import React from "react";
import { NavLink } from "react-router-dom";
import { helpFunc } from "../../helpers/helpFunction";
import { SidebarLogic } from "./SidebarLogic";

export const Sidebar = () => {
  const { hide, onClick, hospitalPlaces, logo, logoConceptica, links } =
    SidebarLogic();

  return (
    <div id="aside">
      <div className="aside-header" onClick={onClick}>
        <i className="fas fa-bars collapse-aside"></i>
        <i
          className={
            hide
              ? "fas fa-caret-right collapse-aside"
              : "fas fa-caret-left collapse-aside"
          }
        ></i>
      </div>
      <div className="aside-content">
        {hide ? (
          <ul className="main-menu">
            <li>
              <NavLink to="/patients-beds">
                <i className="fas fa-bed"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/patients-visits">
                <i className="fas fa-user-injured"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/requisitions/hospitalization">
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports">
                <i className="fas fa-chart-line"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings">
                <i className="fas fa-cog"></i>
              </NavLink>
            </li>
            {links.map((link) => (
              <li key={link.id}>
                <a href={link.url} target="_blank">
                  <i className="far fa-file"></i>
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="main-menu">
            <li>
              <NavLink to="/patients-beds">
                <i className="fas fa-bed"></i>
                <span>Hospitalizovaní pacienti</span>
              </NavLink>
              <ul>
                {hospitalPlaces.hospitals}
                <li>
                  <NavLink to="/all-clinics/hospitalization">
                    Všechny kliniky
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/patients-visits">
                <i className="fas fa-user-injured"></i>
                <span>Ambulantní pacienti</span>
              </NavLink>
              <ul>
                {hospitalPlaces.ambulances}
                <li>
                  <NavLink to="/patients-external/present">
                    <span>Externi pacienti</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/patient-new">
                    <span>Nový pacient</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/all-clinics/ambulation">
                    Všechny kliniky
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/requisitions/hospitalization">
                <i className="fa fa-clipboard" aria-hidden="true"></i>
                <span>Nezpracované žádanky</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports">
                <i className="fas fa-chart-line"></i>
                <span>Statisky a reporty</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings">
                <i className="fas fa-cog"></i>
                <span>Nastavení aplikace</span>
              </NavLink>
            </li>
            {links.map((link) => (
              <li key={link.id}>
                <a href={link.url} target="_blank">
                  <i className="far fa-file"></i>
                  <span>{link.name}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="aside-footer">
        <img
          src={hide ? logo.small : logo.full}
          className="mb-4"
          alt="logo"
          style={
            hide
              ? { width: 44, height: "auto" }
              : { width: 200, height: "auto" }
          }
        />
        <span className="mb-2">
          iPharm v0.0.123
          <br /> ({helpFunc.currentDate()})
        </span>

        {!hide && (
          <>
            <img
              src={logoConceptica}
              alt="logo"
              style={{ width: 150, height: "auto" }}
            />
            <span>
              Tato aplikace byla vyvinuta společností
              <br /> Conceptica
            </span>
          </>
        )}
      </div>
    </div>
  );
};
